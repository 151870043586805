/*global $, jQuery, alert, console*/
    "use strict";
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
        }
    };

    let downloadingFiles = [];

    // Dropzone.autoDiscover = false;

    downloadFile();
    function downloadFile() {
        let filesLength = 0;
        let downloadSize = 0;
        let maxSize = 26214400;
        let maxLength = 16;
        $('#downloadsFilesInput').on('change', function() {
            checkDownload($(this));
            addFile($(this));
        });

        $('#files-list').on('click', '.remove-file__link', function(e) {
            e.preventDefault();
            removeFile($(this));
        });

        function checkDownload(input) {
            let thisFiles = [];
            let thisInputFiles = $(input)[0].files;
            for (let i=0; i < thisInputFiles.length; i++) {
                thisFiles.push(thisInputFiles[i]);
            }

            let thisSize = 0;
            let thisLength = thisFiles.length;

            for (let i=0; i < thisLength; i++) {
                for (let file of downloadingFiles) {
                    if (file.name == thisFiles[i].name) {
                        thisFiles.splice(i, 1);
                    }
                }
            }


            for (let i=0; i < thisLength; i++) {
                thisSize += thisFiles[i].size;
            }
            if ((thisSize + downloadSize) > maxSize || (thisLength + filesLength) > maxLength) {
                showErrordownload();
                return false
            } else {
                downloadSize += thisSize;
                filesLength += thisLength;
                $('.downoladed-size-info').text(formatBytes(downloadSize));
                hideErrordownload();
            }

        }



        function removeFile(file) {
            let removeName = $(file).prev('.file-name').text();
            let removeSize = 0;
            for (let i=0; i < downloadingFiles.length; i++) {
                if (downloadingFiles[i].name == removeName) {
                    removeSize = downloadingFiles[i].size;
                    downloadingFiles.splice(i, 1);
                }
            }
            downloadSize -= removeSize;
            $('.downoladed-size-info').text(formatBytes(downloadSize));
            $(file).closest('.download-file-wrap').remove();
            if (!downloadingFiles.length) {
                $('#briefProjectDubleStepForm').attr('required', 'required');
                if ($('#briefProjectDubleStepForm').val() == '') {
                    $('#briefProjectDubleStepForm').removeClass('valid').addClass('invalid').after('<span class="form-error">Required</span>')
                } else {
                    $('#briefProjectDubleStepForm').removeClass('invalid').addClass('valid')
                }
            }
        };

        function addFile(input) {
            let thisFiles = $(input)[0].files;
            for (let i=0; i < thisFiles.length; i++) {
                downloadingFiles.push(thisFiles[i]);
                $('#files-list').append('<div class="download-file-wrap"><span class="file-name">' + thisFiles[i].name + '</span><a class="remove-file__link" href="#">Remove file</a></div>')
            }
            $('#downloadsFilesInput').val('');
            $('#briefProjectDubleStepForm').attr('required', false).removeClass('invalid').addClass('valid').next('.form-error').remove();
        }

        function formatBytes(bytes,decimals) {
            if(bytes == 0) return 0;
            var k = 1024;
            var dm = decimals + 1 || 2;
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            $('.size-format').text(sizes[i]);
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        }

        function showErrordownload() {
            $('#files-list + .error-upload').show().text('You can attach 15 files max. The total size of files is 25MB max. If you want to attach more than 15 files or files larger than 25MB in total, please upload them to a cloud file store (Google Drive, Dropbox, etc.) and insert a link to the store in the “Describe your project” field.');
        }
        function hideErrordownload() {
            $('#files-list + .error-upload').hide();
        }
    }


    // $('#my-awesome-dropzone').dropzone({
    //     autoProcessQueue: false,
    //     addRemoveLinks: "dictCancelUpload",
    //     init: function() {
    //         var addedFileValue = [];
    //         this.on("addedfile", function(file) {
    //             downloadSize += +(file.size).toFixed(2);
    //             $('#files-list + .error-upload').hide();
    //             if (downloadSize > 26214400 || this.files.length === 16) {
    //                 this.removeFile(file);
    //                 showErrordownload();
    //                 $('.dropzone').removeClass('dz-started');
    //                 return false;
    //             }
    //             $('.downoladed-size-info').text(formatBytes(downloadSize));
    //             $('.preloader-wrapper-download').show();
    //         });
    //         this.on("removedfile", function(file) {
    //             $('input.dz-hidden-input').addClass('wpcf7-form-control').addClass('wpcf7-file').attr('name', 'file-02');
    //             downloadSize -= +(file.size).toFixed(2);
    //             var removedFileValue = file.previewElement.getAttribute('data-value');
    //             $('#topForm input[type=hidden]').each(function() {
    //                 if ($(this).val() === removedFileValue) {
    //                     $(this).remove();
    //                 }
    //             });
    //             $('#topFormBC input[type=hidden]').each(function() {
    //                 if ($(this).val() === removedFileValue) {
    //                     $(this).remove();
    //                 }
    //             });
    //             if (!this.files.length) {
    //                 $('#briefProjectDubleStepForm').attr('required', 'required');
    //                 if (!$('#briefProjectDubleStepForm').val().length) {
    //                     $('#briefProjectDubleStepForm').removeClass("valid");
    //                     $("#my-awesome-dropzone").closest("form").find("button").addClass("off").attr("disabled", "disabled");
    //                 }
    //             }
    //             $('.downoladed-size-info').text(formatBytes(downloadSize));
    //         });
    //         this.on("canceled", function() {
    //             if (!$('.dropzone .dz-preview').length) {
    //                 $('.dropzone').removeClass('dz-started');
    //             }
    //         });
    //         this.on("complete", function() {
    //             if (!$('.dropzone .dz-preview').length) {
    //                 $('.dropzone').removeClass('dz-started');
    //                 $('.preloader-wrapper-download').hide();
    //             }
    //         });
    //         this.on("error", function() {
    //             alert("Sorry, an error has occurred, please try again!");
    //             $('.preloader-wrapper-download').hide();
    //         });
    //         this.on("success", function(data, response) {
    //             if(response === '0') {
    //                 $('.dropzone .dz-preview.dz-success').addClass('dz-error');
    //                 alert("Sorry, an error has occurred, please try again!");
    //             }
    //             $('#topForm').append('<input type="hidden" name="files_ids[]" value="'+response+'" >');
    //             $('#topFormBC').append('<input type="hidden" name="files_ids[]" value="'+response+'" >');
    //
    //
    //             // var arrayOfInputs = [];
    //             addedFileValue = [];
    //             $('.dropzone .dz-preview.dz-success').attr('data-value', response).appendTo('#files-list');
    //             if (!$('.dropzone .dz-preview').length) {
    //                 $('.dropzone').removeClass('dz-started');
    //             }
    //
    //
    //             if (this.files.length) {
    //                 var textareaDescr = $('#briefProjectDubleStepForm');
    //                 textareaDescr.removeAttr('required').removeClass('invalid').addClass('valid');
    //                 textareaDescr.next('.form-error').remove();
    //                 $("#my-awesome-dropzone").addClass("not-empty");
    //
    //                 var formItems = $("#my-awesome-dropzone").closest("form").find("input.form-control, textarea.form-control");
    //                 var disableButton = false;
    //
    //                 formItems.each(function() {
    //                     if(!$(this).hasClass('valid')) {
    //                         disableButton = true;
    //                     }
    //                 });
    //
    //                 if (!disableButton) {
    //                     $("#my-awesome-dropzone").closest("form").find("button").removeClass("off").removeAttr("disabled");
    //                 }
    //             }
    //             $('.preloader-wrapper-download').hide();
    //
    //         });
    //     }
    // });


















    // var Persona = {
    //     api_url: "https://www.altoroslabs.com/api.html"
    // };
    // if(window.location.host == 'www.altoros.fi') {
    //     Persona.api_url = 'https://www.altoros.fi/api.html';
    // }
    // $("form#modalForm").on("submit", function (event) {
    //     event.preventDefault();
    //
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             var modalForm = M.Modal.getInstance($('#modalFormBlock'));
    //             var modalThanks = M.Modal.getInstance($('.thanks-modal'));
    //             M.Modal.init($('#modalFormBlock'), {onCloseEnd: openThanks()});
    //             function openThanks() {
    //                 setTimeout(function() {
    //                     modalThanks.open()
    //                 },1000)
    //             }
    //
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //
    //             modalForm.close();
    //             setTimeout(function () {
    //                 modalThanks.close();
    //             },7000);
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#modalForm2").on("submit", function (event) {
    //     event.preventDefault()
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             var modalForm = M.Modal.getInstance($('#modalFormBlock2'));
    //             var modalThanks = M.Modal.getInstance($('.thanks-modal'));
    //             M.Modal.init($('#modalFormBlock2'), {onCloseEnd: openThanks()});
    //             function openThanks() {
    //                 setTimeout(function() {
    //                     modalThanks.open()
    //                 },1000)
    //             }
    //
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //
    //             modalForm.close();
    //             setTimeout(function () {
    //                 modalThanks.close();
    //             },7000);
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#modalForm3").on("submit", function (event) {
    //     event.preventDefault()
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             var modalForm = M.Modal.getInstance($('#modalFormBlock3'));
    //             var modalThanks = M.Modal.getInstance($('.thanks-modal2'));
    //             M.Modal.init($('#modalFormBlock3'), {onCloseEnd: openThanks()});
    //
    //             function openThanks() {
    //                 setTimeout(function () {
    //                     modalThanks.open()
    //                 }, 1000)
    //             }
    //
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //
    //             modalForm.close();
    //             setTimeout(function () {
    //                 modalThanks.close();
    //             }, 7000);
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#modalFormToThanksPage").on("submit", function (event) {
    //     event.preventDefault();
    //
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#modalFormToThanksPage2").on("submit", function (event) {
    //     event.preventDefault();
    //
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#modalFormToThanksPage3").on("submit", function (event) {
    //     event.preventDefault();
    //
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    //
    //
    //
    //
    //
    // $("form#modalFormApplied").on("submit", function (event) {
    //     event.preventDefault()
    //
    //     var sendBtn = $(this).find('.submitButton')
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             var modalForm = M.Modal.getInstance($('#modal-contact-form'));
    //             var modalThanks = M.Modal.getInstance($('.thanks-modal-cases'));
    //             M.Modal.init($('#modal-contact-form'), {onCloseEnd: openThanks()});
    //
    //             function openThanks() {
    //                 setTimeout(function () {
    //                     modalThanks.open()
    //                 }, 1000)
    //             }
    //
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //
    //             modalForm.close();
    //             setTimeout(function () {
    //                 modalThanks.close();
    //             }, 7000);
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#bottomForm").on("submit", function (event) {
    //     event.preventDefault();
    //     var sendBtn = $(this).find('.submitButton');
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $("form#stickyForm").on("submit", function (event) {
    //     event.preventDefault();
    //     var sendBtn = $(this).find('.submitButton');
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //         //need validate if Ok:
    //
    //         // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
    //
    //         $.post(Persona.api_url, $(this).serialize()).done(function () {
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //         return false;
    //     }
    //
    // });
    //
    // $('#topFormBC').submit(function (e) {
    //
    //     e.preventDefault();
    //     var sendBtn = $(this).find('.submitButton');
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //     var m_method = $(this).attr('method');
    //     var m_action = $(this).attr('action');
    //
    //     var titleValue = $(this).find('#title_p').val(),
    //         companyName = $(this).find('#company_name_p').val(),
    //         startDate = $('#startDateSelect').val(),
    //         descriptionValue = $('#briefProjectDubleStepForm').val(),
    //         hiddenTextarea = $('#pr_desc_3');
    //
    //     function convertDateToUTC(date) {
    //         return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    //     }
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //
    //         var c = convertDateToUTC(new Date());
    //         c.setHours(c.getHours() + 3);
    //         hiddenTextarea.val("TITLE: " + titleValue + "; COMPANY NAME: " + companyName + "; DESCRIBE PROJECT: " + descriptionValue + " DATE&TIME SUBMIT FORM : " + c);
    //
    //         var m_data = $(this).serialize();
    //         $.post(m_action, m_data).done(function () {
    //
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //     }
    //
    // });
    // $('#topForm').submit(function (e) {
    //     e.preventDefault();
    //     var sendBtn = $(this).find('.submitButton');
    //     var preloaderBtn = $(this).find('.preloader-wrapper-btn');
    //     var m_method = $(this).attr('method');
    //     var m_action = $(this).attr('action');
    //
    //     var budgetValue = $('#budgetSelect').val(),
    //         startDate = $('#startDateSelect').val(),
    //         descriptionValue = $('#briefProjectDubleStepForm').val(),
    //         hiddenTextarea = $('#pr_desc_3');
    //
    //     function convertDateToUTC(date) {
    //         return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    //     }
    //
    //     if(!$(this).hasClass('notvalid')) {
    //         sendBtn.hide();
    //         preloaderBtn.show();
    //
    //
    //         var c = convertDateToUTC(new Date());
    //         c.setHours(c.getHours() + 3);
    //         hiddenTextarea.val("BUDGET: " + budgetValue + "; PROJECT TYPE: " + startDate + "; DESCRIBE PROJECT: " + descriptionValue + " DATE&TIME SUBMIT FORM : " + c);
    //
    //         var m_data = $(this).serialize();
    //         $.post(m_action, m_data).done(function () {
    //
    //             switch (window.location.origin) {
    //                 case "https://www.altoroslabs.com" :
    //                     location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
    //                     break;
    //                 case  "https://www.altoros.fi" :
    //                     location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
    //                     break;
    //             }
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //         }).fail(function () {
    //             sendBtn.show();
    //             preloaderBtn.hide();
    //             alert('Sorry, an error has occurred, please try again!');
    //         });
    //     }
    //
    // });


// $(window).bind("pageshow", function(event) {
//     if (event.originalEvent.persisted) {
//         $('#files-list .dz-preview').remove();
//         $('input[name="files_ids[]"]').each(function() {
//             $(this).remove();
//         })
//     }
// });


$(document).ready(function () {
    var source, medium, term, content, campaign, session_count, pageview_count;

    function get_campaign_info() {
        var utma = get_utm_value(document.cookie, '__utma=', ';');
        var utmb = get_utm_value(document.cookie, '__utmb=', ';');
        var utmc = get_utm_value(document.cookie, '__utmc=', ';');
        var utmz = get_utm_value(document.cookie, '__utmz=', ';');

        source = get_utm_value(utmz, 'utmcsr=', '|');
        medium = get_utm_value(utmz, 'utmcmd=', '|');
        term = get_utm_value(utmz, 'utmctr=', '|');
        content = get_utm_value(utmz, 'utmcct=', '|');
        campaign = get_utm_value(utmz, 'utmccn=', '|');

        session_count = get_session_count(utma);
        pageview_count = get_pageview_count(utmb, utmc);
    }

    function get_utm_value(l, n, s) {
        if (!l || l == "" || !n || n == "" || !s || s == "") return "-";
        var i, j, k, utm = "-";
        i = l.indexOf(n);
        k = n.indexOf("=") + 1;

        if (i > -1) {
            j = l.indexOf(s, i);
            if (j < 0) {
                j = l.length;
            }
            utm = l.substring((i + k), j);
        }
        return utm;
    }

//This function extracts the "Count of Sessions" value from the _utma cookie
    function get_session_count(str) {
        var i, vc = '-';
        if (str != '-') {
            i = str.lastIndexOf(".");
            i++;
            vc = str.substring(i);
        }
        return vc;
    }

//This function extracts the "Count of Pageviews" value from the _utmb cookie
    function get_pageview_count(utmb, utmc) {
        var i, j, pc = '-';
        if (utmb != '-' && utmc != '-') {
            utmc = utmc + '.';

            i = utmc.length;
            j = utmb.indexOf(".", i);
            pc = utmb.substring(i, j);
        }
        return pc;
    }

    get_campaign_info();

    $('div.formWP').append('<div style="display:none"><input type="hidden" name="p" value="">' +
        '<input type="hidden" name="client_id" value="">' +
        '<input type="hidden" name="campaign_source" value="">' +
        '<input type="hidden" name="campaign_channel" value="">' +
        '<input type="hidden" name="campaign_name" value="">' +
        '<input type="hidden" name="campaign_id" value="">' +
        '<input type="hidden" name="ad_group" value="">' +
        '<input type="hidden" name="search_queries" value="">' +
        '<input type="hidden" name="search_terms" value="">' +
        '<input type="hidden" name="session_count" value="">' +
        '<input type="hidden" name="matchtype" value="">' +
        '<input type="hidden" name="device" value=""></div>');

    $('input[name="url_page"]').val(window.location.href);

    let date = new Date(new Date().getTime() + 60 * 48 * 3600 * 1000);

    $('input[name="session_count"]').val(session_count);

    // $('input[name="keyword_match_type"]').val('keyword');

    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    /**
     * BEGIN: UTM метки
     * все метки вида utm_.* в query части запроса должны обрабатыватся
     */

    let queryDict = {};

    let search = location.href.split("?");

    if (search) {
        search.shift();
        search.join('&').split("&").forEach(function (item) {
            queryDict[item.split("=")[0]] = item.split("=")[1];
        });
    }

    let utm_list = [
        {key: 'p', sugar: 'p'},
        {key: 'utm_source', sugar: 'campaign_source'},
        {key: 'utm_medium', sugar: 'campaign_channel'},
        {key: 'utm_campaign', sugar: 'campaign_name'},
        {key: 'utm_id', sugar: 'campaign_id'},
        {key: 'utm_term', sugar: 'search_queries'}, //Keyword
        {key: 'utm_content', sugar: 'ad_group'},
        {key: 'term', sugar: 'search_terms'},
        {key: 'matchtype', sugar: 'matchtype'},
        {key: 'device', sugar: 'device'},
    ];

    utm_list.forEach(function (utm_item) {
        utm_item.val = '';
        if (getCookie(utm_item.key) !== "undefined") {
            utm_item.val = getCookie(utm_item.key);
        }
        if (typeof queryDict[utm_item.key] !== "undefined") {
            document.cookie = utm_item.key + "=" + queryDict[utm_item.key] + ";path=/;expires=" + date.toUTCString();
            utm_item.val = decodeURIComponent(queryDict[utm_item.key]);
        }
        $('input[name="' + utm_item.sugar + '"]').val(utm_item.val);

        if (utm_item.key === 'utm_source' && utm_item.val === 'influ2') {
            $('input[name="p"]').val(utm_item.val);
        }
    });

    if (window.location.href.indexOf("utm_campaign") > -1) {
        document.cookie = 'url_page' + "=" + window.location.href + ";path=/;expires=" + date.toUTCString();
    }

    /**
     * END: UTM метки
     */
    $(document).change(function () {
        if(ga.length){
            $('input[name="client_id"]').val(ga.getAll()[0].get('clientId'));
            $('input[name="gcl_id"]').val(ga.getAll()[0].get('_gclid'));
        }
    });
});


    $(document).ready(function () {
        detectSeenElements();
        openIframePage();
        addInlineSvg();
        controlFixetForm();
        controlPhoneTabs();
        copyOnClipboard();
        controlVideoCustomers();
        controlStepForm();
        controlBottomStepForm();
        // contactFormsConfig();
        controlOutsorcedBlock();
        $('.lazy').Lazy();



        $('body').on('click','.gdpr-cookie-notice-modal-footer-item-save', function () {
            $('.gdpr-cookie-notice-modal').css('display', 'none');
            $('.gdpr-cookie-notice').css('display', 'none');
        });


        detectSeenElements();
        function detectSeenElements() {
            var sections = $('section');


            $(window).scroll(function() {

                sections.each(function () {
                    var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                    var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                    var top_of_screen = $(window).scrollTop();

                    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                        $(this).addClass('seen')
                    } else {
                        // the element is not visible, do something else
                    }
                })
            });
        }

        function controlOutsorcedBlock() {
            $('.s-outsourced__tab').on('click', function() {
                var curId = $(this).attr('data-href');
                $('.s-outsourced__tab').each(function() {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');

                $('.s-outsourced__res-block').each(function () {
                    $(this).removeClass('active');
                    if ($(this).attr('data-id') == curId) {
                        $(this).addClass('active');
                    }
                })
            })
        }

        function controlStepForm() {
            $('.step-btn_next').on('click', function (e) {
                e.preventDefault();
                var flag = false;
                $(this).closest('.step-block').find('.form-control').each(function () {
                    if($(this).hasClass('invalid')) {
                        flag = false;
                        return false;
                    } else {
                        flag = true;
                    }
                });

                if(flag) {
                    $(this).closest('.step-block').removeClass('active').next('.step-block').addClass('active');
                    $(this).closest('form').find('.form-control').each(function() {
                        $(this).removeClass('invalid');
                    })
                    $(this).closest('form').find('.form-error').each(function() {
                        $(this).remove();
                    })
                }
            });

            $('.step-btn_back').on('click', function (e) {
                e.preventDefault();
                $(this).closest('.step-block').removeClass('active').prev('.step-block').addClass('active');
            })
        }

        function controlBottomStepForm() {
            $('.bottom-step-checkbox').on('click', function () {
                $(this).closest('.step-wrap').find('.form-error').remove();
                let flag = false;
                $(this).closest('.step-wrap').find('input[type="checkbox"]').each(function () {
                    if ($(this).is(":checked")) {
                        flag = true;
                    }
                });
                if (!flag) {
                    $(this).closest('.step-wrap').find('.bottom-step-checkboxes-wrap').append('<span class="form-error">Choose one of the checkboxes above</span>')
                }
            })
            $('.bottom-step__next-btn').on('click', function (e) {
                e.preventDefault();
                $(this).closest('.step-wrap').find('.form-error').remove();
                let flag = false;
                $(this).closest('.step-wrap').find('input[type="checkbox"]').each(function () {
                    if ($(this).is(":checked")) {
                        flag = true;
                    }
                });
                if (!flag) {
                    $(this).closest('.step-wrap').find('.bottom-step-checkboxes-wrap').append('<span class="form-error">Choose one of the checkboxes above</span>');
                    return false;
                }


                $(this).closest('.step-wrap').removeClass('active').next('.step-wrap').addClass('active');
                let curId = $('#bottomFormStepsWrap .step-wrap.active').last().attr('id');
                $('#bottomFormStepsWrap .step-number-block-wrap').each(function () {
                    if ($(this).attr('data-href') == curId) {
                        $(this).addClass('active');
                    }
                });
                $('html, body').animate({scrollTop: $('#bottomFormStepsWrap').offset().top - 100}, 500);

            });

            $('.bottom-step__prev-btn').on('click', function (e) {
                e.preventDefault();
                let curId = $(this).closest('.step-wrap').attr('id');
                $(this).closest('.step-wrap').removeClass('active').prev('.step-wrap').addClass('active');
                $('#bottomFormStepsWrap .step-number-block-wrap').each(function () {
                    if ($(this).attr('data-href') == curId) {
                        $(this).removeClass('active');
                    }
                });
                $('html, body').animate({scrollTop: $('#bottomFormStepsWrap').offset().top - 100}, 500);
            })
        }


        controlTopStepForm2();
        function controlTopStepForm2() {
            $('.step1 .s-contact-step-top__block').on('click', function () {
                if (!$(this).hasClass('active')) {
                    $('.step1').addClass('valid');
                    $('.step1').removeClass('unvalid');
                    var curId = $(this).attr('data-href');
                    $('.step1 .s-contact-step-top__block').each(function () {
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                    $('.step2').each(function () {
                        $(this).removeClass('active');
                        if ($(this).attr('data-id') == curId) {
                            $(this).addClass('active');
                        }
                    })
                } else {
                    $('.step1').removeClass('unvalid');
                    $('.step1').removeClass('valid');
                    $(this).removeClass('active');
                    $('.step2').each(function () {
                        $(this).removeClass('active');
                    })
                }
            });

            $('.step1 .next-btn').on('click', function (e) {
                e.preventDefault();
                if (!$('.step1').hasClass('valid')) {
                    $('.step1').addClass('unvalid');
                } else {
                    $('.step1').hide();
                    $('.step2.active').show();
                }
            });

            $('.step2 .s-contact-step-top__block').on('click', function () {
                $('.step2').removeClass('unvalid');
                $(this).toggleClass('active');
            });

            $('.step2 .next-btn').on('click', function (e) {
                e.preventDefault();
                var valid = false;
                $('.step2 .s-contact-step-top__block').each(function () {
                   if ($(this).hasClass('active')) {
                       valid = true;
                   }
                });

                if (!valid) {
                    $('.step2').addClass('unvalid');
                } else {
                    $('.step2').hide();
                    $('.step3').show();
                }
            });

            $('.step3 .prev-btn').on('click', function (e) {
                e.preventDefault();
                $('.step3').hide();
                $('.step2.active').show();
            })

            $('.step2 .prev-btn').on('click', function (e) {
                e.preventDefault();
                $('.step2').hide();
                $('.step1').show();
            })
        }

        function controlVideoCustomers() {
            $('.video-wrap__overlay').on('click', function() {
               $(this).hide();
               $('#videoCustomers')[0].play();
            });
        }

        function copyOnClipboard() {
            $('.copy-block').on('click', function() {
                var copyText = $(this).prev('a').text();
                navigator.clipboard.writeText(copyText);
                var element = $(this);
                element.addClass('active');
                element.addClass('active-copy');
                setTimeout(function(){ element.removeClass('active'); }, 200);
                setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
            })
        }

        function controlPhoneTabs() {
            $('.custom-tabs .custom-tabs__tab-link').on('click', function(e) {
                e.preventDefault();
                $('.custom-tabs .custom-tabs__tab-link').each(function() {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
                var curId = $(this).attr('href').slice(1);
                $('.custom-tab__result-wrap .custom-tab-result').each(function() {
                    if ($(this).prop('id') != curId) {
                        $(this).removeClass('active');
                    } else {
                        $(this).addClass('active');
                        return
                    }
                });
            })
        };




        function addInlineSvg() {
            $('img.image_svg').each(function(){
                var $img = jQuery(this);
                var imgID = $img.attr('id');
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                jQuery.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = jQuery(data).find('svg');

                    // Add replaced image ID to the new SVG
                    if(typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image classes to the new SVG
                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                    if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('width') + ' ' + $svg.attr('height'))
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        }
        
        function detectSeenElements() {
            var sections = $('section');


            $(window).on('scroll', function () {
                var cur_pos = $(this).scrollTop();

                sections.each(function() {
                    var top = $(this).offset().top - $( window ).height()/2,
                        bottom = top + $(this).outerHeight();
                    $(this).removeClass('visible');
                    if (cur_pos >= top && cur_pos <= bottom) {
                        $(this).addClass('visible');
                        $(this).addClass('seen');
                    }
                });
            });
            var cur_pos = $(window).scrollTop();

            sections.each(function() {
                var top = $(this).offset().top - $( window ).height()/2,
                    bottom = top + $(this).outerHeight();
                $(this).removeClass('visible');
                if (cur_pos >= top && cur_pos <= bottom) {
                    $(this).addClass('visible');
                    $(this).addClass('seen');
                }

            });
        }

        function openIframePage() {
            if (window.location.pathname == "/iframe") {
                var urlParams = window.location.href.substring(window.location.href.indexOf('?')).slice(1).split('&');
                var oldUrl;
                var iframeHref;
                $.each(urlParams, function(index, value) {
                    if (value.substring(0, 3) == 'ref') {
                        oldUrl = value.slice(4);
                    } else if (value.substring(0, 10) == 'url_iframe') {
                        iframeHref = value.slice(11);
                    }
                });
                $.each(urlParams, function(index, value) {
                    if (value.substring(0, 3) != 'ref' && value.substring(0, 10) != 'url_iframe') {
                        iframeHref = iframeHref + "&" + value;
                        oldUrl = oldUrl + "&" + value;
                    }
                });
                // var oldUrl = urlParams[0].slice(4);
                // var iframeHref = urlParams[1].slice(4);
                $('.s-iframe-page iframe').attr('src', iframeHref);
                $('.b-wrapper').next('.gdpr-cookie-notice').hide();
                $("body, html").css('overflow','hidden');
                $('.s-menu-iframe__back-btn').on('click', function() {
                    window.location.href = oldUrl;
                })
                $('.s-menu-iframe__close-btn').on('click', function() {
                    window.location.href = iframeHref;
                })
            }
            $('.iframe-link-page').on('click', function (e) {
                e.preventDefault();
                var curLink = window.location.href;
                var iframeHref = $(this).attr('href');
                window.location.href = "https://www.altoroslabs.com/iframe?" + "ref=" + curLink + "&" + "url_iframe=" + iframeHref;
            });
        }

        function controlFixetForm() {
            if ($('.s-topform-fixed').length) {
                $('.s-topform-fixed').fixTo('.content');
            }

            $('.s-topform-fixed .form-group input').each(function() {
                if ($(this).val() != '') {
                    $(this).closest('.form-group').find('label').css({
                        "font-size":"12px",
                        "top":"4px"
                    });
                }
            });

            $(window).click(function() {
                $('.s-topform-fixed').removeClass('active');
            });
            $('.s-topform-fixed').on('click', function(e) {
                e.stopPropagation();
                $(this).addClass('active');
            });
            $('.s-topform-fixed__close-link').on('click', function(e) {
                e.stopPropagation();
                $(this).closest('.s-topform-fixed').removeClass('active');
            });

            $('.s-topform-fixed .form-group input').on('focus', function() {
                $(this).closest('.form-group').find('label').css({
                    "font-size":"12px",
                    "top":"4px"
                });
            });

            $('.s-topform-fixed .form-group input').on('focusout', function() {
                if ($(this).val() == '') {
                    $(this).closest('.form-group').find('label').css({
                        "font-size":"15px",
                        "top":"10px"
                    });
                }
            });
        }


        $('.s-partners-tabs__link').on('click', function(e) {
            e.preventDefault();
            var curId = $(this).attr('href').slice(1);
            $('.s-partners-tabs__links-wrap .s-partners-tabs__link').each(function() {
                $(this).removeClass('active');
            });
            $('.s-partners-tabs__blocks-wrap .s-partners-tabs__block').each(function() {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
            $(this).addClass('active');
        });

        $('.s-expertise-software_slider .s-expertise-software-block').on('click', function () {
            $('.s-expertise-software_slider .s-expertise-software-block').each(function() {
                $(this).removeClass('active');
            })
            $(this).addClass('active');
        });

        $('.s-expertise-v3__header').on('click', function () {
            if ($(this).closest('.s-expertise-v3__expertise').hasClass('active')) {
                $(this).closest('.s-expertise-v3__expertise').removeClass('active');
            } else {
                $('.s-expertise-v3__expertise').each(function() {
                    $(this).removeClass('active');
                });
                $(this).closest('.s-expertise-v3__expertise').addClass('active');
            }
        });



        $('.s-services-mini__show-link').on('click', function(e) {
            e.preventDefault();
            var curNum = 0;
            $('.s-services-mini__block').each(function() {
                curNum++;
                $(this).removeClass('active').removeClass('start-align').attr("data-number", curNum);
            });
            $(this).closest('.s-services-mini__block').addClass('active');
            if ($(this).closest('.s-services-mini__block').attr("data-number") % 2 == 0) {
                $(this).closest('.s-services-mini__block').prev('.s-services-mini__block').addClass('start-align');
            }
            curNum = 0;
        });
        $('.s-services-mini__close-link').on('click', function(e) {
            e.preventDefault();
            $('.s-services-mini__block').each(function() {
                $(this).removeClass('active')
            });
        });

        $('.s-benefits-link').on('click', function() {
            var curId = $(this).attr('href').slice(1);
            $('.s-benefits-link').each(function(){
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            $('.s-benefits').each(function() {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
            $('html, body').animate({scrollTop: $(this).offset().top - 100}, 500);
        });

        $('.s-approach-bc-circle-tab-wrap').on('click', function() {
            $('.s-approach-bc-circle-tab-wrap').each(function() {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        });
        $('.s-offer .tab-link').on('click', function(e) {
            e.preventDefault();
            var curId = $(this).attr('href').slice(1);
            $('.s-offer .tab-link').each(function() {
               $(this).removeClass('active');
            });
            $('.s-offer .s-offer-result').each(function() {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
            $(this).addClass('active');
        });

        $('.s-services-best-block').on('click', function() {
            $(this).toggleClass('active');
            $(this).siblings('.s-services-best-block').removeClass('active');
        });
        $('.chose-link').on('click', function(e) {
            e.preventDefault();
            clearInterval(blinkSWRok);
            $('.s-arrow-work-plan').removeClass('slow-delay');
            $('.s-arrow-work-plan').removeClass('blink');
            var curId = $(this).attr('href').slice(1);
            $('.chose-link').each(function() {
                $(this).removeClass('active');
                if($(this).attr('href').slice(1) == curId) {
                    $(this).addClass('active');
                }
            });
            $('.chose-link-res').each(function () {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
        });
        $('.chose-link-scale').on('click', function(e) {
            e.preventDefault();
            var curId = $(this).attr('href').slice(1);
            $('.chose-link-scale').each(function() {
                $(this).removeClass('active');
            });
            $('.chose-link-res-scale').each(function () {
                $(this).removeClass('active');
                if($(this).attr('id') == curId) {
                    $(this).addClass('active');
                }
            });
            $(this).addClass('active');
        });

        $('.back_to_search a, .project-link-wrap').on('click', function() {
            $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 50}, 1000);
            controlMainCases();
        });
        // $('form').each(function(){
        //     if($(this).find('#first_name_p').val()!='' ){
        //         $(this).find('button').removeAttr('disabled').removeClass('off');
        //     }
        // });

        $('.dropdown-trigger-hover').mouseenter(function() {
            var modifyBlockHeight = $(this).next('.dropdown-content-modify').outerHeight();
            if(($('.dropdown-trigger-hover').offset().top - $(window).scrollTop()) >= 500) {
                $('.dropdown-content-modify').css('top','-' + modifyBlockHeight + 'px');
            } else {
                $('.dropdown-content-modify').css('top','44px');
            }
        });
        var blinkSWRok = setInterval(function() {
            $('.s-arrow-work-plan').toggleClass('blink');
        },1000);
        $('.modal').modal();
        $('select').formSelect();
        $('.tabs').tabs();
        $('.popup-links-action').magnificPopup({
            type: 'inline',
            preloader: false,
            midClick: true,
            closeOnBgClick:true,
            callbacks: {
                beforeOpen: function() {
                    jQuery('body').css('overflow', 'hidden');
                },
                beforeClose: function() {
                    jQuery('body').css('overflow', 'auto');
                }
            }
        });

        $(document).ready(function(){
            $('.collapsible').collapsible();
            $('.collapsible.expandable').collapsible({
                accordion: false
            })
        });
        $(document).on('click', '.popup-modal-dismiss-contact', function() {
            $.magnificPopup.close();
        });
        $(document).on('click', '.mfp-close', function (e) {
            // e.preventDefault();
            $.magnificPopup.close();
        });
        $(document).ready(function(){
            $('.sidenav').sidenav({
                edge: 'right'
            });
        });

        // $('.iframe-select-wrap').on('click',function() {
        //     $('.video-altoros').each(function() {
        //         $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        //     });
        // });

        $('.animated-scroll').on('click', function (e) {
            e.preventDefault();
            var linkHref = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(linkHref).offset().top - 80
            }, 500);
        });


        $('.s-clients__overlay-wrap').on('click', function () {
            $('#videoCustomersModal').get(0).play();
        })

        $('#videoCustomersModalWrap').modal({
            onCloseEnd: function () {
                $('#videoCustomersModal').get(0).pause();
            }
        });







        var scrollHeight = $(window).scrollTop();

        if(scrollHeight  > 0) {
            $('.top-menu').css('background-color', 'RGBA(20,126,133,.9)');
            $('.top-menu.menu-notfixed').height('50');
        } else if (scrollHeight  == 0 && $(window).width() > 992) {
            $('.top-menu').css('background-color', 'rgba(255,255,255,.05)')
            $('.top-menu.menu-notfixed').height('84');
        } else if (scrollHeight  == 0 && $(window).width() <= 992) {
            $('.top-menu').css('background-color', 'transparent')
            $('.top-menu.menu-notfixed').height('84');
        }
        slickSliderControl();


        $('.s-why-partner__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            arrows: false,
            centerMode: false,
            infinite: true,
            dots: true,
            autoplay: true,
            cssEase: 'linear',
            speed: 500,
            draggable: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            adaptiveHeight: true,
            autoplaySpeed: 3000
        });

        controlExpertiseSlider();
        function controlExpertiseSlider() {
            if ($('.s-expertise-slider__slider').hasClass('slick-slider')) {
                $('.s-expertise-slider__slider').slick('unslick').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="img/ic-arrow-left-normal.svg"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="img/ic-arrow-right-normal.svg"></div>'
                });
            } else {
                $('.s-expertise-slider__slider').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="img/ic-arrow-left-normal.svg"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="img/ic-arrow-right-normal.svg"></div>'
                });
            }
            $('.s-expertise-slider__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
                $('.s-expertise-slider__cases-wrap .number_active').text(currentSlide + 1);
            });

            if ($('.s-expertise-slider-2__slider').hasClass('slick-slider')) {
                $('.s-expertise-slider-2__slider').slick('unslick').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: false,
                    centerMode: false,
                    infinite: true,
                    dots: true,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false
                });
            } else {
                $('.s-expertise-slider-2__slider').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: false,
                    centerMode: false,
                    infinite: true,
                    dots: true,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false
                });
            }

            if ($('.s-expertise-slider-2__logos-slider').hasClass('slick-slider')) {
                $('.s-expertise-slider-2__logos-slider').slick('unslick').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: false,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: true,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    autoplaySpeed: 3000
                });
            } else {
                $('.s-expertise-slider-2__logos-slider').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: false,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: true,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    autoplaySpeed: 3000
                });
            }

            $( ".s-expertise-slider-2__logos-slider" ).mouseenter(function() {
                $(".s-expertise-slider-2__logos-slider").slick('slickNext');
            });



            addInlineSvg();
        }

        controlMainCases();
        function controlMainCases() {
            if ($('.s-cases__slider').hasClass('slick-slider')) {
                $('.s-cases__slider').slick('unslick').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="img/ic-arrow-left-normal.svg"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="img/ic-arrow-right-normal.svg"></div>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            } else {
                $('.s-cases__slider').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="img/ic-arrow-left-normal.svg"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="img/ic-arrow-right-normal.svg"></div>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
            $('.s-cases__cases-wrap .number_active').text('1');
            $('.s-cases__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
                $('.s-cases__cases-wrap .number_active').text(currentSlide + 1);
            });



            if ($('.s-clients__slider').hasClass('slick-slider')) {
                $('.s-clients__slider').slick('unslick').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="img/ic-arrow-left-normal.svg"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="img/ic-arrow-right-normal.svg"></div>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            } else {
                $('.s-clients__slider').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                    dots: false,
                    autoplay: false,
                    cssEase: 'linear',
                    speed: 500,
                    draggable: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    prevArrow: '<div class="prev-btn"><img class="image_svg" src="img/ic-arrow-left-normal.svg"></div>',
                    nextArrow: '<div class="next-btn"><img class="image_svg" src="img/ic-arrow-right-normal.svg"></div>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
            $('.s-clients__cases-wrap .number_active').text('1');
            $('.s-clients__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
                $('.s-clients__cases-wrap .number_active').text(currentSlide + 1);
            });



            addInlineSvg();
        }

        function slickSliderControl() {
            $('.s-offer-tab-wrap-mobile').on('afterChange', function(event, slick, currentSlide, nextSlide){
                var curId = $(this).find('.slick-active').attr('href').slice(1);
                $('.s-offer-result-wrap-mobile .s-offer-result').each(function() {
                    $(this).removeClass('active');
                    if($(this).attr('id') == curId) {
                        $(this).addClass('active');
                    }
                });
            });
            $('.s-expertise-software_slider .s-expertise-software-block-img-wrap').slick({
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: 5,
                slidesToScroll: 3,
                centerMode: false,
                variableWidth: true,
                arrows: true,
                prevArrow: '<div class="prev-btn"><img src="img/why-arrow-hover.svg"></div>',
                nextArrow: '<div class="next-btn"><img src="img/why-arrow-hover.svg"></div>',
                responsive: [
                    {
                        breakpoint: 1150,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
            $('.s-work-example__content-slider').slick({
                variableWidth: true,
                arrows: true,
                centerMode: true,
                infinite: true,
                prevArrow: '<div class="prev-btn"><i class="material-icons">keyboard_arrow_left</i></div>',
                nextArrow: '<div class="next-btn"><i class="material-icons">keyboard_arrow_right</i></div>',
                autoplay: true,
                autoplaySpeed: 2000
            });
            $('.s-team-new__content').slick({
                variableWidth: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                centerMode: true,
                infinite: true,
                prevArrow: '<div class="prev-btn"><img src="img/arrow-green-left.png" style="width:26px" alt=""></div>',
                nextArrow: '<div class="next-btn"><img src="img/arrow-green-right.png" style="width:26px" alt=""></div>',
                autoplay: false,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 560,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
            $('.s-customers-wrap-slider').slick({
                variableWidth: true,
                arrows: false,
                centerMode: true,
                infinite: true,
                dots: false,
                autoplay: true,
                cssEase: 'linear',
                autoplaySpeed: 0,
                speed: 2500,
                draggable: false,
                pauseOnFocus: false,
                pauseOnHover: false
            });
            $('.s-partners__logos-wrap').slick({
                variableWidth: true,
                arrows: false,
                centerMode: true,
                infinite: true,
                dots: false,
                autoplay: true,
                cssEase: 'linear',
                autoplaySpeed: 0,
                speed: 2500,
                draggable: false,
                pauseOnFocus: false,
                pauseOnHover: false
            });
            $('.s-arrow-work-wrap-mobile').slick({
                variableWidth: true,
                arrows: false,
                centerMode: false,
                infinite: false
            });
            $('.s-offer-tab-wrap-mobile').slick({
                variableWidth: true,
                arrows: true,
                centerMode: false,
                infinite: false,
                prevArrow: '<div class="prev-btn"><img src="img/arrow-green-left.png" style="width:26px" alt=""></div>',
                nextArrow: '<div class="next-btn"><img src="img/arrow-green-right.png" style="width:26px" alt=""></div>'
            });
            if ($(window).width() <= 992) {
                $('.s-expertise-tabs').slick({
                    variableWidth: true,
                    arrows: false,
                    centerMode: false,
                    infinite: false
                })
            }

            if ($(window).width() <= 992) {
                $('.s-scale-wrap').slick({
                    variableWidth: true,
                    arrows: false,
                    centerMode: false,
                    infinite: false
                })
            }


            if ($(window).width() <= 767) {
                $('.s-phone-tab__image-block').slick({
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 1,
                    prevArrow: '<div class="prev-btn"><div class="custom-arrow custom-arrow-left"></div></div>',
                    nextArrow: '<div class="next-btn"><div class="custom-arrow custom-arrow-right"></div></div>',
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                centerMode: false,
                                variableWidth: true
                            }
                        }
                    ]

                })
            }



            $('.video-slider').slick({
                dots: true,
                // infinite: true,
                // speed: 300,
                // slidesToShow: 1,
                // variableWidth: false,
                // arrows: true,
                prevArrow: '<div class="prev-btn"><div class="custom-arrow custom-arrow-left"></div></div>',
                nextArrow: '<div class="next-btn"><div class="custom-arrow custom-arrow-right"></div>',
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            adaptiveHeight: true
                        }
                    }
                ]
            });
            $('.video-slider-v3').slick({
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                variableWidth: false,
                arrows: true,
                prevArrow: '<div class="prev-btn"><div class="custom-arrow custom-arrow-left"><img class="image_svg" src="img/video-customers-arrow.svg" alt=""></div></div>',
                nextArrow: '<div class="next-btn"><div class="custom-arrow custom-arrow-right"><img class="image_svg" src="img/video-customers-arrow.svg" alt=""></div>',
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            adaptiveHeight: true
                        }
                    }
                ]
            });
            $('.popup-slider').slick({
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                centerMode: true,
                variableWidth: true,
                arrows: true,
                centerPadding: '60px',
                prevArrow: '<div class="prev-btn"><img src="img/ic-arrow-left-normal.svg" style="width:16px" alt=""></div>',
                nextArrow: '<div class="next-btn"><img src="img/ic-arrow-right-normal.svg" style="width:16px" alt=""></div>'
            });

            var popupImageCounter = 0;
            if ($(window).width() > 320) {
                $('.popup-slider').each(function() {
                    popupImageCounter = 0;
                    $(this).find('.image-wrap').each(function() {
                        popupImageCounter++;
                    });
                    if (popupImageCounter == 1) {
                        $(this).slick('unslick');
                    }
                });
            }


            $(".project-link-wrap-new .project-link").click(function(){
                // $('.popup-slider').slick("slickSetOption", "centerMode", true, false);
                $('.popup-slider').removeClass('showslider');
                $('.popup-slider').slick('slickPrev');

                setTimeout(function(){ $('.popup-slider').addClass('showslider')}, 800);
                if($('.portfolio-slider').find('image-wrap').length == 1){
                    $('.portfolio-slider_nav').addClass('hide-arrows');
                }
            });
            $(".prev-project").click(function(){
                $('.popup-slider').removeClass('showslider');
                $('.popup-slider').slick('slickPrev');
                setTimeout(function(){ $('.popup-slider').addClass('showslider')}, 800);
            });
            $(".next-project").click(function(){
                $('.popup-slider').removeClass('showslider');
                $('.popup-slider').slick('slickPrev');
                setTimeout(function(){ $('.popup-slider').addClass('showslider')}, 800);
            });


            $(window).resize(function() {

                if ($(window).width() <= 767 && !$('.s-phone-tab__image-block').hasClass('slick-slider')) {
                    $('.s-phone-tab__image-block').slick({
                        centerMode: true,
                        centerPadding: '60px',
                        slidesToShow: 1,
                        prevArrow: '<div class="prev-btn"><div class="custom-arrow custom-arrow-left"></div></div>',
                        nextArrow: '<div class="next-btn"><div class="custom-arrow custom-arrow-right"></div></div>',
                        responsive: [
                            {
                                breakpoint: 600,
                                settings: {
                                    centerMode: false,
                                    variableWidth: true
                                }
                            }
                        ]
                    })
                } else if ($(window).width() > 767 && $('.s-phone-tab__image-block').hasClass('slick-slider')) {
                    $('.s-phone-tab__image-block').slick('unslick');
                }



                if ($(window).width() <= 992 && !$('.s-expertise-tabs').hasClass('slick-slider')) {
                    $('.s-expertise-tabs').slick({
                        variableWidth: true,
                        arrows: false,
                        centerMode: false,
                        infinite: false
                    })
                } else if ($(window).width() > 992 && $('.s-expertise-tabs').hasClass('slick-slider')) {
                    $('.s-expertise-tabs').slick('unslick');
                }


                if ($(window).width() <= 992 && !$('.s-scale-wrap').hasClass('slick-slider')) {
                    $('.s-scale-wrap').slick({
                        variableWidth: true,
                        arrows: false,
                        centerMode: false,
                        infinite: false
                    })
                } else if ($(window).width() > 992 && $('.s-scale-wrap').hasClass('slick-slider')) {
                    $('.s-scale-wrap').slick('unslick');
                }

                if($(window).width() <= 1200) {
                    $('.popup-slider').each(function() {
                        popupImageCounter = 0;
                        $(this).find('.image-wrap').each(function() {
                            popupImageCounter++;
                        });
                        if (!$(this).hasClass('slick-slider') && popupImageCounter > 1) {
                            $(this).slick({
                                dots: true,
                                infinite: true,
                                speed: 300,
                                slidesToShow: 1,
                                centerMode: true,
                                variableWidth: true,
                                arrows: true,
                                centerPadding: '60px',
                                prevArrow: '<div class="prev-btn"><img src="img/ic-arrow-left-normal.svg" style="width:16px" alt=""></div>',
                                nextArrow: '<div class="next-btn"><img src="img/ic-arrow-right-normal.svg" style="width:16px" alt=""></div>'
                            });
                        }
                    });
                } else if ($(window).width() > 320) {
                    $('.popup-slider.slick-slider').each(function() {
                        popupImageCounter = 0;
                        $(this).find('.image-wrap').each(function() {
                            popupImageCounter++;
                        });
                        if (popupImageCounter == 1) {
                            $(this).slick('unslick');
                        }
                    });
                }


            });
        }





        // $('.iframe-select-wrap').on('click', function() {
        //     var curNum = $(this).attr('custom-num');
        //     $('.select-blocks-wrap .iframe-select-wrap').each(function() {
        //         $(this).removeClass('hidden-block');
        //     });
        //     $(this).addClass('hidden-block');
        //     $('.s-customers-video-block').removeClass('active');
        //     $('.s-customers-wrap .s-customers-video-block').each(function () {
        //         if($(this).attr('custom-num') === curNum) {
        //             $(this).addClass('active');
        //         }
        //     });
        // });



        controlProject();

        if($(window).width() <= 1200) {
            controlProjectMobile()
        }


        function controlProject() {

            var projectLink = $('.project-link-wrap'),
                linksBlock = $('.project-container-main'),
                projectWrap = $('.projects-container-descr .project-wrap'),
                paginationText = $('.project-wrap .person_pag span'),
                showProjectsLinksButton = $('.back_to_search a'),
                prevProjectButton = $('.person_pag .prev-project'),
                nextProjectButton = $('.person_pag .next-project'),
                counterProjects = 0,
                counterPage = 0,
                activePageNumber = 0,
                pagination_block = $('.pagination-block-container');


            addPaginationblock();



            function addPaginationblock() {
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 8);
                    if (projectPages > 1) {
                        $(this).hide();
                        pagination_block.hide();
                    } else {
                        $(this).show();
                        pagination_block.show();
                    }
                    if (projectPages == 1) {
                        pagination_block.hide();
                        $('.pagination-project').hide();
                    } else {
                        pagination_block.show();
                        $('.pagination-project').show();
                    }
                    counterProjects++;
                });
                $('.pagination-project').html('');
                $('.pagination-project').append('<li class="page-item project-link-prev"><a class="page-link"><div class="left-arrow"></div><div class="overlay"></div></a></li>');
                for (var i=0; i <= counterProjects; i+=9) {
                    counterPage++;
                    $('.pagination-project').append('<li class="page-item page-number" data-number="' + counterPage + '"><a class="page-link">' + counterPage + '</a></li>');
                    if (counterPage === 1) {
                        $('.pagination-project .page-item').addClass('active');
                    }
                }
                $('.pagination-project').append('<li class="page-item project-link-next"><a class="page-link"><div class="right-arrow"></div><div class="overlay"></div></a></li>');
                $('.project-link-prev').attr('disabled', 'true');
            };

            $('.page-number').on('click', function() {
                if(!$(this).hasClass('active')) {
                    $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 30}, 1000);
                }
                var pageNumber = +$(this).find('.page-link').text();
                $('.pagination-project .page-item').removeClass('active');
                $(this).addClass('active');
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 8);
                    if (projectPages === pageNumber) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });

                if (pageNumber === 1) {
                    $('.project-link-prev').attr('disabled', 'true');
                } else if (pageNumber > 1) {
                    $('.project-link-prev').removeAttr('disabled');
                }
                if (pageNumber === counterPage) {
                    $('.project-link-next').attr('disabled', 'true');
                } else if (pageNumber < counterPage) {
                    $('.project-link-next').removeAttr('disabled');
                }
            });

            $('.project-link-prev').on('click', function() {
                if ($(this).attr('disabled')) {
                    return false;
                }
                $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 30}, 1000);
                activePageNumber = +$('.pagination-project .page-number.active').find('.page-link').text();
                var pageNumber = activePageNumber - 1;
                $('.pagination-project .page-number').each(function() {
                    if (+$(this).attr('data-number') === pageNumber) {
                        $(this).addClass('active');
                    }
                    else if (+$(this).attr('data-number') === activePageNumber) {
                        $(this).removeClass('active');
                    }
                });
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 8);
                    if (projectPages === pageNumber) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
                if (pageNumber === 1) {
                    $('.project-link-prev').attr('disabled', 'true');
                } else if (pageNumber > 1) {
                    $('.project-link-prev').removeAttr('disabled');
                }
                if (pageNumber === counterPage) {
                    $('.project-link-next').attr('disabled', 'true');
                } else if (pageNumber < counterPage) {
                    $('.project-link-next').removeAttr('disabled');
                }
            });


            $('.project-link-next').on('click', function() {
                if ($(this).attr('disabled')) {
                    return false;
                }
                $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 30}, 1000);
                activePageNumber = +$('.pagination-project .page-number.active').find('.page-link').text();
                var pageNumber = activePageNumber + 1;
                $('.pagination-project .page-number').each(function() {
                    if (+$(this).attr('data-number') === pageNumber) {
                        $(this).addClass('active');
                    }
                    else if (+$(this).attr('data-number') === activePageNumber) {
                        $(this).removeClass('active');
                    }
                });
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 8);
                    if (projectPages === pageNumber) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
                if (pageNumber === 1) {
                    $('.project-link-prev').attr('disabled', 'true');
                } else if (pageNumber > 1) {
                    $('.project-link-prev').removeAttr('disabled');
                }
                if (pageNumber === counterPage) {
                    $('.project-link-next').attr('disabled', 'true');
                } else if (pageNumber < counterPage) {
                    $('.project-link-next').removeAttr('disabled');
                }
            });

            projectLink.on('click', function() {
                var projectOrder = +$(this).attr('data-number');
                counterProjects = 0;
                linksBlock.hide();
                projectWrap.each(function() {
                    counterProjects++;
                    if (+$(this).attr('data-number') === projectOrder) {
                        $(this).show();
                    }
                });

                paginationText.text("(" + projectOrder + " of " + counterProjects + ")");
                pagination_block.hide();

            });
            $('.prev-project, .next-project').on('click',function(){
                $('html, body').animate({
                    scrollTop: $("#portfolioSection").offset().top - 50
                }, 500);
            });


            showProjectsLinksButton.on('click', function() {
                projectWrap.hide();
                linksBlock.show();
                pagination_block.show();
            });

            prevProjectButton.on('click', function() {
                var thisOrder = +$(this).closest('.project-wrap').attr('data-number');
                counterProjects = 0;
                projectWrap.hide();
                projectWrap.each(function() {
                    counterProjects++;
                    if (+$(this).attr('data-number') === (thisOrder - 1)) {
                        $(this).show();
                    }
                });


                paginationText.text("(" + (thisOrder - 1) + " of " + counterProjects + ")");
                if (thisOrder === 1) {
                    projectWrap.each(function() {
                        if (+$(this).attr('data-number') === counterProjects) {
                            $(this).show();
                        }
                    });
                    paginationText.text("(" + counterProjects + " of " + counterProjects + ")");
                }
            });

            nextProjectButton.on('click', function() {
                var thisOrder = +$(this).closest('.project-wrap').attr('data-number');
                counterProjects = 0;
                projectWrap.hide();
                projectWrap.each(function() {
                    counterProjects++;
                    if (+$(this).attr('data-number') === (thisOrder + 1)) {
                        $(this).show();
                    }
                });


                paginationText.text("(" + (thisOrder + 1) + " of " + counterProjects + ")");
                if (thisOrder === counterProjects) {
                    projectWrap.each(function() {
                        if (+$(this).attr('data-number') === 1) {
                            $(this).show();
                        }
                    });
                    paginationText.text("(" + 1 + " of " + counterProjects + ")");
                }
            });
        }
        function controlProjectMobile() {

            var projectLink = $('.project-link-wrap'),
                linksBlock = $('.project-container-main'),
                projectWrap = $('.projects-container-descr .project-wrap'),
                paginationText = $('.project-wrap .person_pag span'),
                showProjectsLinksButton = $('.back_to_search a'),
                prevProjectButton = $('.person_pag .prev-project'),
                nextProjectButton = $('.person_pag .next-project'),
                counterProjects = 0,
                counterPage = 0,
                activePageNumber = 0,
                pagination_block = $('.pagination-block-container');


            addPaginationblock();



            function addPaginationblock() {
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 6);
                    if (projectPages > 1) {
                        $(this).hide();
                        pagination_block.hide();
                    } else {
                        pagination_block.show();
                        $(this).show();
                    }
                    if (projectPages == 1) {
                        pagination_block.hide();
                        $('.pagination-project').hide();
                    } else {
                        pagination_block.show();
                        $('.pagination-project').show();
                    }
                    counterProjects++;
                });
                $('.pagination-project').html('');
                $('.pagination-project').append('<li class="page-item project-link-prev"><a class="page-link"><div class="left-arrow"></div><div class="overlay"></div></a></li>');
                for (var i=0; i <= counterProjects; i+=7) {
                    counterPage++;
                    $('.pagination-project').append('<li class="page-item page-number" data-number="' + counterPage + '"><a class="page-link">' + counterPage + '</a></li>');
                    if (counterPage === 1) {
                        $('.pagination-project .page-item').addClass('active');
                    }
                }
                $('.pagination-project').append('<li class="page-item project-link-next"><a class="page-link"><div class="right-arrow"></div><div class="overlay"></div></a></li>');
                $('.project-link-prev').attr('disabled', 'true');
            };

            $('.page-number').on('click', function() {
                if(!$(this).hasClass('active')) {
                    $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 30}, 1000);
                }
                var pageNumber = +$(this).find('.page-link').text();
                $('.pagination-project .page-item').removeClass('active');
                $(this).addClass('active');
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 6);
                    if (projectPages === pageNumber) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });


                if (pageNumber === 1) {
                    $('.project-link-prev').attr('disabled', 'true');
                } else if (pageNumber > 1) {
                    $('.project-link-prev').removeAttr('disabled');
                }
                if (pageNumber === counterPage) {
                    $('.project-link-next').attr('disabled', 'true');
                } else if (pageNumber < counterPage) {
                    $('.project-link-next').removeAttr('disabled');
                }

            });

            $('.project-link-prev').on('click', function() {
                if ($(this).attr('disabled')) {
                    return false;
                }
                $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 30}, 1000);
                activePageNumber = +$('.pagination-project .page-number.active').find('.page-link').text();
                var pageNumber = activePageNumber - 1;
                $('.pagination-project .page-number').each(function() {
                    if (+$(this).attr('data-number') === pageNumber) {
                        $(this).addClass('active');
                    }
                    else if (+$(this).attr('data-number') === activePageNumber) {
                        $(this).removeClass('active');
                    }
                });
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 6);
                    if (projectPages === pageNumber) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
                if (pageNumber === 1) {
                    $('.project-link-prev').attr('disabled', 'true');
                } else if (pageNumber > 1) {
                    $('.project-link-prev').removeAttr('disabled');
                }
                if (pageNumber === counterPage) {
                    $('.project-link-next').attr('disabled', 'true');
                } else if (pageNumber < counterPage) {
                    $('.project-link-next').removeAttr('disabled');
                }
            });


            $('.project-link-next').on('click', function() {
                if ($(this).attr('disabled')) {
                    return false;
                }
                $('html, body').animate({scrollTop: $('#portfolioSection').offset().top - 30}, 1000);
                activePageNumber = +$('.pagination-project .page-number.active').find('.page-link').text();
                var pageNumber = activePageNumber + 1;
                $('.pagination-project .page-number').each(function() {
                    if (+$(this).attr('data-number') === pageNumber) {
                        $(this).addClass('active');
                    }
                    else if (+$(this).attr('data-number') === activePageNumber) {
                        $(this).removeClass('active');
                    }
                });
                projectLink.each(function() {
                    var projectPages = Math.ceil(+$(this).attr('data-number') / 6);
                    if (projectPages === pageNumber) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
                if (pageNumber === 1) {
                    $('.project-link-prev').attr('disabled', 'true');
                } else if (pageNumber > 1) {
                    $('.project-link-prev').removeAttr('disabled');
                }
                if (pageNumber === counterPage) {
                    $('.project-link-next').attr('disabled', 'true');
                } else if (pageNumber < counterPage) {
                    $('.project-link-next').removeAttr('disabled');
                }
            });

            projectLink.on('click', function() {
                var projectOrder = +$(this).attr('data-number');
                counterProjects = 0;
                linksBlock.hide();
                projectWrap.each(function() {
                    counterProjects++;
                    if (+$(this).attr('data-number') === projectOrder) {
                        $(this).show();
                    }
                });
                paginationText.text("(" + projectOrder + " of " + counterProjects + ")");
                pagination_block.hide();

            });

            showProjectsLinksButton.on('click', function() {
                projectWrap.hide();
                linksBlock.show();
                pagination_block.show();
            });

            prevProjectButton.on('click', function() {
                var thisOrder = +$(this).closest('.project-wrap').attr('data-number');
                counterProjects = 0;
                projectWrap.hide();
                projectWrap.each(function() {
                    counterProjects++;
                    if (+$(this).attr('data-number') === (thisOrder - 1)) {
                        $(this).show();
                    }
                });


                paginationText.text("(" + (thisOrder - 1) + " of " + counterProjects + ")");
                if (thisOrder === 1) {
                    projectWrap.each(function() {
                        if (+$(this).attr('data-number') === counterProjects) {
                            $(this).show();
                        }
                    });
                    paginationText.text("(" + counterProjects + " of " + counterProjects + ")");
                }
            });

            nextProjectButton.on('click', function() {
                var thisOrder = +$(this).closest('.project-wrap').attr('data-number');
                counterProjects = 0;
                projectWrap.hide();
                projectWrap.each(function() {
                    counterProjects++;
                    if (+$(this).attr('data-number') === (thisOrder + 1)) {
                        $(this).show();
                    }
                });


                paginationText.text("(" + (thisOrder + 1) + " of " + counterProjects + ")");
                if (thisOrder === counterProjects) {
                    projectWrap.each(function() {
                        if (+$(this).attr('data-number') === 1) {
                            $(this).show();
                        }
                    });
                    paginationText.text("(" + 1 + " of " + counterProjects + ")");
                }
            });
        }

        // controlBeforeLeaveWithoutForm();
        // function controlBeforeLeaveWithoutForm() {
        //     var ModalWasShow = false;
        //     var isLeave = null;
        //
        //     $('input').on('click', function () {
        //
        //         window.addEventListener('mousemove', function (e) {
        //
        //             let y = e.clientY;
        //
        //             if(y > 20) isLeave = false;
        //
        //             if(y <= 20 && !ModalWasShow && isLeave === false) {
        //                 ModalWasShow = true;
        //                 $('.popup-before-leave-2').modal('open');
        //             }
        //         })
        //
        //         // $('body').on('mouseleave', function () {
        //         //     if(!ModalWasShow) {
        //         //         ModalWasShow = true;
        //         //         $('.popup-before-leave-2').modal('open');
        //         //     }
        //         // });
        //
        //
        //         $('.popup-close-and-scroll').on('click', function (e) {
        //             e.preventDefault();
        //             $(this).closest('.modal').modal('close');
        //             $('html, body').animate({
        //                 scrollTop: $('#bottomForm').offset().top - 80
        //             }, 500);
        //         })
        //     });
        // }


        // function contactFormsConfig() {
        //     try {
        //         $('input[name=url_page]').val(location.origin + location.pathname);
        //         $('input[name=lead_source_description]').val('AltorosLabs | '+ location.pathname);
        //     }catch (e) {
        //
        //     }
        // }


        $( window ).resize(function() {
            if($(window).width() <= 1200) {
                controlProjectMobile()
            } else {
                controlProject();
            }


            if(scrollHeight  > 0) {
                $('.top-menu').css('background-color', 'RGBA(20,126,133,.9)');
                $('.top-menu.menu-notfixed').height('50');
            } else if (scrollHeight  == 0 && $(window).width() > 992) {
                $('.top-menu').css('background-color', 'rgba(255,255,255,.05)')
                $('.top-menu.menu-notfixed').height('84');
            } else if (scrollHeight  == 0 && $(window).width() <= 992) {
                $('.top-menu').css('background-color', 'transparent')
                $('.top-menu.menu-notfixed').height('84');
            }
        });
    });


    window.addEventListener("load", function() {
        if ($('#video-bg').length) {
            if(!isMobile.any()) {
                $('#video-bg').get(0).play();
            } else {
                $('#video-bg').hide();
            }
        }
    });
    $(window).scroll(function() {
        var scrollHeight = $(window).scrollTop();

        if(scrollHeight  > 0) {
            $('.top-menu').css('background-color', 'RGBA(20,126,133,.9)');
            $('.top-menu.menu-notfixed').height('50');
        } else if (scrollHeight  == 0 && $(window).width() > 992) {
            $('.top-menu').css('background-color', 'rgba(255,255,255,.05)')
            $('.top-menu.menu-notfixed').height('84');
        } else if (scrollHeight  == 0 && $(window).width() <= 992) {
            $('.top-menu').css('background-color', 'transparent')
            $('.top-menu.menu-notfixed').height('84');
        }
    });

    $('.s-about-tab__tab-link').on('click', function (e) {
        e.preventDefault();
        var curId = $(this).attr('data-id');

        $('.s-about-tab__tab-link').each(function () {
            $(this).removeClass('active');
            if ($(this).attr('data-id') == curId) {
                $(this).addClass('active');
            }
        })

        $('.s-about-tab__res-wrap').each(function () {
            $(this).removeClass('active');
            if ($(this).attr('data-id') == curId) {
                $(this).addClass('active');
            }
        })
    });


    /*$(function() {
        var limit = 60 * 24 * 3600 * 1000;
        var localStorageInitTime = localStorage.getItem('priv_agr');
        if(+new Date() - localStorageInitTime > limit) {
            localStorage.clear();
        }
        if (localStorageInitTime === null) {
            $('#agreement_popup').show('slow');
            $('.s-footer .container').addClass('second-footer');
            $('.agree-with-privacy').on('click', function () {
                $('#agreement_popup').hide('slow');
                $('.s-footer .container').removeClass('second-footer');
                localStorage.setItem('priv_agr', +new Date());
            });
        }
    });*/



    $(function() {
        $.get("https://ipinfo.io/json", function (response) {
            // if(response.country === "GB"){
            //     $('a.phone-link').removeClass('uk-link');
            // } else


            function detectNoEurope() {
                var notEurope = ['US','AU','CA','AR','IN','MX','BR','CL','VE','PE','CO'];

                var flag = true;
                for (const el of notEurope) {
                    if (response.country === el) {
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    $('a.phone-link').removeClass('uk-link');
                }
            }
            detectNoEurope();



            if (response.country === "US") {
                $('a.phone-link').removeClass('usa-link');
                // $('.s-about-altoros-bottom').addClass('usa-block_hidden');
            }
        }, "jsonp");
    });

    gdprCookieNotice({
        locale: 'en', //This is the default value
        timeout: 500, //Time until the cookie bar appears
        expiration: 60, //This is the default value, in days
        domain: '.altoroslabs.de', //If you run the same cookie notice on all subdomains, define the main domain starting with a .
        implicit: false, //Accept cookies on page scroll automatically
        statement: 'https://www.altoroslabs.com/privacy_policy.html', //Link to your cookie statement page
        performance: ['JSESSIONID', '_hjIncludedInSample', 'nQ_visitId', 'nQ_cookieId', 'locale', 'lang', '__atuvc', '__hstc', '__hssrc', '__hssc', 'hsfirstvisit', 'hubspotutk'], //Cookies in the performance category.
        analytics: ['ga', '_ga', '_gid', '_gat', 'collect', '_ga_user_id', '__utmz', '__utma', 'gwcc'], //Cookies in the analytics category.
        marketing: ['SSID', 'IDE', 'test_cookie', 'fr', 'tr', '1P_JAR', 'id', 'NID', 'APISID', 'SAPISID', 'guest_id', 'personalization_id', 'bcookie', 'bscookie', 'lidc', 'MUID', 'MUIDB', 'GPS'] //Cookies in the marketing category.
    });

    document.addEventListener('DOMContentLoaded', () => {

        function googlePie() {
            google.charts.load('current', {'packages':['corechart']});

            google.charts.setOnLoadCallback(drawChart);

            function drawChart() {
                let data = new google.visualization.DataTable();
                data.addColumn('string', 'staff');
                data.addColumn('number', 'slices');
                data.addRows([
                    ['AI/ML', 1.7],
                    ['Java', 1.9],
                    ['QA', 2.1],
                    ['Front-end', 2.8],
                    ['Cloud Orchestration', 3.3],
                    ['Net', 3.5],
                    ['Blockchain', 4.1]
                ]);

                // Set chart options
                let options = {
                    width: 450,
                    height: 450,
                    legend: 'none',
                    borderColor: 'none',
                    pieSliceBorderColor: 'none',
                    tooltip: {
                        trigger: 'none'
                    },
                    colors: ['#cee81b', '#eb4753', '#fc733b', '#f2e616', '#6bdc6e', '#4ebfe2', '#5679fd']
                };

                if(window.innerWidth < 1151) {
                    options.enableInteractivity = false
                }

                let itemsBg = document.querySelectorAll('.s-staff__items-bg > div');

                if(document.querySelectorAll('.s-staff__items-bg > div').length === 0) return;

                itemsBg = [...itemsBg];

                function selectHandler(entry) {

                    if(window.innerWidth < 1151) return;

                    /**
                     * Hide block with numbers
                     */
                    if(document.querySelector('.s-staff__items') !== null) {
                        document.querySelector('.s-staff__items').classList.add('hide-items');
                    }

                    if(window.innerWidth > 1151) {
                        chart.setSelection([{row: entry.row}]);
                    }


                    let selectedItem = chart.getSelection()[0];
                    if (selectedItem) {
                        let topping = data.getValue(selectedItem.row, 0);

                        removeActiveBgBlock(itemsBg);
                        setActiveBgBlock(itemsBg, topping);
                    }

                    /**
                     * Move titles ahead
                     */
                    if(document.querySelector('.s-staff__schema-title') !== null && document.querySelector('.s-staff__schema-inner-title') !== null) {
                        document.querySelector('.s-staff__schema-title').classList.add('move-title');
                        document.querySelector('.s-staff__schema-inner-title').classList.add('move-title');
                    }

                    window.addEventListener('mouseout', (e) => {
                        /**
                         * Hide active bg block and show numbers if out of schema
                         */
                        if (e.target.closest('.s-staff__schema') === null) return;

                        if (e.target === document.querySelector('.s-staff__schema rect')) {
                            chart.setSelection([]);

                            if(document.querySelector('.s-staff__items') !== null) document.querySelector('.s-staff__items').classList.remove('hide-items');

                            removeActiveBgBlock(itemsBg);

                            /**
                             * Move titles back
                             */
                            if(document.querySelector('.s-staff__schema-title') !== null && document.querySelector('.s-staff__schema-inner-title') !== null) {
                                document.querySelector('.s-staff__schema-title').classList.remove('move-title');
                                document.querySelector('.s-staff__schema-inner-title').classList.remove('move-title');
                            }
                        }
                    });
                }

                /**
                 * Set active bg block
                 */
                function setActiveBgBlock(parentBlock, selected) {
                    let className = 'active-item-bg';

                    for(let i in parentBlock) {
                        if(itemsBg[i].dataset.pie === selected) itemsBg[i].classList.add(className);
                    }
                }

                /**
                 * Remove active bg block
                 */
                function removeActiveBgBlock(parentBlock) {
                    let className = 'active-item-bg';
                    let active = parentBlock.filter(item => item.classList.contains(className));
                    if(active.length > 0) active.forEach(item => item.classList.remove(className));
                }


                let chart;

                function initPies() {
                    if(window.innerWidth < 1151) {
                        removeActiveBgBlock(itemsBg);
                        document.querySelector('.s-staff__items').classList.remove('hide-items');
                    }

                    if(document.getElementById('s-staff__schema') === null) return;

                    chart = new google.visualization.PieChart(document.getElementById('s-staff__schema'));
                    google.visualization.events.addListener(chart, 'onmouseover', selectHandler);
                    chart.draw(data, options);
                }

                window.addEventListener('resize', initPies);

                /**
                 * Init google Pie
                 */
                if(document.querySelector('.s-staff__items') !== null) initPies();
            }
        }

        googlePie();


        function roulette() {
            let roulette_pieces = document.querySelectorAll('.roulette-circle');
            roulette_pieces = [...roulette_pieces];

            $('.roulette-circle').each(function () {
                $(this).on('click', function () {
                    /**
                     * Hide text
                     */
                    $('.text-border').each(function () {
                        $(this).removeClass('hide');
                    });



                    $('.text-center').each(function () {
                        $(this).removeClass('show');
                    });

                    let isCurrent = $(this).hasClass('active-piece');

                    let prev_clicked_item = roulette_pieces.filter((item)=> item.classList.contains('active-piece'));
                    if(prev_clicked_item.length !== 0) prev_clicked_item[0].classList.remove('active-piece');


                    let prev_item_title = [...document.querySelectorAll('.center_circle > div')].filter((item)=> item.classList.contains('active-content'));
                    if(prev_item_title.length !== 0) prev_item_title[0].classList.remove('active-content');

                    let prev_item_content = [...document.querySelectorAll('.s-roulette_selected > .circle_item')].filter((item)=> item.classList.contains('active'));
                    if(prev_item_content.length !== 0) prev_item_content[0].classList.remove('active');


                    /**
                     * If click on the same block
                     */
                    if(isCurrent) {
                        /**
                         * Show text
                         */
                        $('.text-border').each(function () {
                            $(this).addClass('hide');
                        });

                        $('.text-center').each(function () {
                            $(this).addClass('show');
                        });

                        /**
                         * Show backing
                         */
                        document.querySelector('.s-roulette__backing').classList.remove('circle-animation');
                        document.querySelector('.s-roulette__backing').style.transform = "rotate(180deg)";
                        $('.s-roulette__backing').show();

                        /**
                         * Set default options
                         */
                        document.querySelector('.s-roulette-circles').style.transform = 'rotate(0deg)';
                        document.querySelector('.s-roulette_selected').classList.remove('selected-active');
                        document.querySelector('.center_circle').classList.remove('center_circle-active');
                        return;
                    }

                    $('.circle_item').each(function () {
                        $(this).addClass('hide-show-animation');
                    });

                    /**
                     * Get piece's position of selected click
                     */
                    let getCoordinate = $(this).attr('data-circle');



                    /**
                     * Set position to main circle block
                     */
                    document.querySelector('.s-roulette-circles').style.transform = 'rotate(' + getCoordinate + 'deg)';
                    $(this).addClass('active-piece');

                    /**
                     * Get piece's index
                     */
                    let piece_index = $(this).attr('data-piece');
                    /**
                     * Add active class to center circle
                     */
                    document.querySelector('.center_circle').classList.add('center_circle-active');
                    document.querySelector('.center_circle > div[data-piece="' + piece_index + '"]').classList.add('active-content');

                    /**
                     * Add active class to selected white block & to content
                     */
                    document.querySelector('.s-roulette_selected').classList.add('selected-active');
                    document.querySelector('.s-roulette_selected > .circle_item[data-piece="' + piece_index + '"]').classList.add('active');

                    /**
                     * Set pointer
                     */
                    let pointer = document.querySelector('.center_circle__inner');
                    pointer.classList.add('circle-animation');

                    /**
                     * Hide backing
                     */
                    document.querySelector('.s-roulette__backing').classList.add('circle-animation');
                    $('.s-roulette__backing').hide();
                })
            })
        }

        function setSelector(e, pointer, center) {
            pointer.classList.remove('circle-animation');

            let deg = Math.atan2(e.pageX- center[0],- (e.pageY- center[1]) )*(180/Math.PI);

            pointer.style.transform = 'rotate(' + deg + 'deg)';

            setBacking(e)
        }

        function setBacking(e) {
            if(!$(e.target).hasClass("roulette-circle") || !e.target.closest('.roulette-circle')) return;

            let backing = document.querySelector('.s-roulette__backing');
            let el = Number($(e.target).attr('data-piece'));


            let deg = 0;

            switch (el) {
                case 1:
                    deg = 30;
                    break;
                case 2:
                    deg = 90;
                    break;
                case 3:
                    deg = 150;
                    break;
                case 4:
                    deg = 210;
                    break;
                case 5:
                    deg = 270;
                    break;
                case 6:
                    deg = 330;
                    break;
            }

            backing.style.transform = 'rotate(' + Number(deg) + 'deg)';
        }

        setTimeout(function(){
            roulette();

            if ($('.center_circle__inner').length) {
                let pointer = document.querySelector('.center_circle__inner');
                let center = [$(pointer).offset().left + pointer.offsetWidth/2, $(pointer).offset().top + pointer.offsetWidth/2];

                window.addEventListener('mousemove', function(event) {
                    setSelector(event, pointer, center);
                })
            }

        }, 4000);

        $('#scroll-to-top').on('click', function (e) {
            e.preventDefault();

            $('html, body').animate({scrollTop: 0}, 1000);
        });

        if(document.querySelector('.popup-before-leave') !== null) {

            let isShowed = false;
            let isLeave = null;

            setTimeout(function(){
                window.addEventListener('mousemove', function (e) {

                    let y = e.clientY;

                    if(y > 20) isLeave = false;

                    if(y <= 20 && !isShowed && isLeave === false) {
                        isShowed = true;
                        beforeLeavePopup();
                    }
                })
            }, 10000);
        }

        function beforeLeavePopup() {
            $('.popup-before-leave').modal('open');
        }

        $('.popup-modal-close').on('click', function () {
            $(this).closest('.modal').modal('close');
        })


    });